<template>

  <div class="row" ref="productForm">

    <div class="col-xs-12 col-sm-12 col-md-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>

          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6">
                <ValidationProvider
                  vid="name"
                  rules="required"
                  name="The name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Name"
                            name="name"
                            fou
                            @keyup="generateMetaTitleURL"
                            v-model="formData.name">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="category_id"
                  rules=""
                  name="The Category"
                  rules="required"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="category_id"
                    size="large"
                    filterable
                    placeholder="Select Category"
                    :input-classes="'select-default'"
                    :label="'Category'"
                    :error="failed ? errors[0]: null"
                    :list="categories"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.category_id">
                  </fg-select>
                </ValidationProvider>
                <ValidationProvider
                  vid="brand_id"
                  rules="required"
                  name="The Brand"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="brand_id"
                    size="large"
                    filterable
                    placeholder="Select Brand"
                    :input-classes="'select-default'"
                    :label="'Brand'"
                    :error="failed ? errors[0]: null"
                    :list="brands"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.brand_id">
                  </fg-select>
                </ValidationProvider>

                <div class="form-group">
                  <label>Featured Product</label>&nbsp;
                  <l-switch v-model="formData.is_featured">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6">
                <ValidationProvider
                  vid="meta_description"
                  rules=""
                  name="The Meta Description"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Meta Description"
                            name="meta_description"
                            fou
                            v-model="formData.meta_description">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="meta_title"
                  rules="required"
                  name="The Meta Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Meta Title"
                            name="meta_title"
                            fou
                            v-model="formData.meta_title">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="url"
                  rules="required"
                  name="The url"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Url"
                            name="url"
                            fou
                            v-model="formData.url">
                  </fg-input>
                </ValidationProvider>
                <div class="form-group">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
              </div>
            </div>
            <div class="row mt-4" v-if="formData.is_featured">
              <h4 class="card-title">Featured Image
                <span>(image size 500 * 320)</span>
              </h4>
              <div class="col-12">
                <div class="row">
                  <template>
                    <div class="col-3">
                      <div class="col-12">
                        <label>Image:</label>
                        <image-uploader
                                :preview-width="'200px'"
                                :preview-height="'200px'"
                                v-model="formData.featured_image"></image-uploader>
                      </div>
                      <div class="col-12" style="display: flex ; justify-content: flex-start;align-items: center">
                        <div class="ml-4" v-if="formData.featured_image">
                          <l-button
                                  @click="removeFeaturedImage()"
                                  type="danger" size="sm">
                                <span class="btn-label">
                                   <i class="fa fa-trash"></i>
                                </span>
                          </l-button>
                        </div>
                      </div>
                    </div>

                  </template>
                </div>
              </div>


            </div>
            <div class="row mt-2">
              <div class="col-12">
                <label>Description</label>
                <editor

                  v-model="formData.description"
                  :api-key="editorKey"
                  :init='{
                    height: 300,
                    paste_data_images: true,
                    plugins: [
                    "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                     "searchreplace wordcount visualblocks visualchars code fullscreen",
                    "insertdatetime media nonbreaking save table contextmenu directionality",
                    "emoticons template paste textcolor colorpicker textpattern"
                    ],
                    toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                    toolbar2: "print preview media | forecolor backcolor emoticons",
                    image_advtab: true,
                  }'
                />
              </div>

            </div>
            <div class="row mt-2">
              <h4 class="card-title">Product Sizes</h4>
              <div class="col-12">
                <template v-for="(size,sizeIndex) in formData.sizes">
                  <div class="row">
                    <div class="col-5">
                      <fg-input type="text"
                                label="Title"
                                v-model="size.title">
                      </fg-input>
                    </div>
                    <div class="col-5">
                      <fg-input type="number"
                                label="Order"
                                v-model="size.sort_order">
                      </fg-input>
                    </div>
                    <div class="col-2 align-self-center mt-3">
                      <l-button
                        @click="removeSize(sizeIndex)"
                        type="danger" size="sm">
                      <span class="btn-label">
                         <i class="fa fa-trash"></i>
                      </span>
                      </l-button>
                    </div>
                  </div>
                </template>
              </div>
              <div class="col-12">
                <l-button
                  class="mt-2"
                  type="success"
                  @click="addNewSize()"
                  size="sm">
                       <span class="btn-label">
                         add new size
                         <i class="fa fa-plus"></i>
                         </span>
                </l-button>
              </div>


            </div>
            <div class="row mt-4">
              <h4 class="card-title">Product Images
                <span> (image size 500 * 640)</span>
              </h4>
              <div class="col-12">
                <div class="row">
                  <template v-for="(image,imageIndex) in formData.images">
                    <div class="col-3">
                      <div class="col-12">
                        <label>Image:</label>
                        <image-uploader
                          :preview-width="'200px'"
                          :preview-height="'200px'"
                          v-model="image.image"></image-uploader>
                      </div>
                      <div class="col-12" style="display: flex ; justify-content: flex-start;align-items: center">
                        <fg-input type="number"
                                  label="order"
                                  style="max-width: 50%;"
                                  fou
                                  v-model="image.sort_order">
                        </fg-input>
                        <div class="ml-4" v-if="formData.images && formData.images.length > 1">
                          <l-button
                            @click="removeImage(imageIndex)"
                            type="danger" size="sm">
                                <span class="btn-label">
                                   <i class="fa fa-trash"></i>
                                </span>
                          </l-button>
                        </div>
                      </div>
                    </div>

                  </template>
                </div>
              </div>
              <div class="col-12">
                <l-button
                  class="mt-2"
                  type="success"
                  @click="addNewImage()"
                  size="sm">
                       <span class="btn-label">
                         add new image
                         <i class="fa fa-plus"></i>
                         </span>
                </l-button>
              </div>


            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>

            <l-button @click="$router.push('/products/list')" type="danger" wide>Cancel</l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import FgSelect from "@/components/Inputs/formGroupSelect";
import {FormGroupSelect, ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'

extend("required", {
  message: "{_field_} is required"
});

export default {
  components: {
    FgSelect,
    LSwitch,
    FormGroupSelect,
    ImageUploader,
    'editor': Editor
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey:process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        name: "",
        description: "",
        category_id: null,
        brand_id: null,
        url: "",
        meta_title: "",
        meta_description: "",
        is_active: false,
        is_featured: false,
        sort_order: null,
        images: [],
        sizes: [],
        featured_image: "",
      },
      categories: [],
      brands: [],

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.productForm
    });

    this.id = this.$route.params['id'];

    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Product";
    } else {
      this.editMode = false;
      this.formTitle = "Add Product";
    }
    this.prepareBuilders();
  },


  methods: {

    async getProduct() {
      if (!this.editMode) {
        this.formData.images.push({"id": null, "image": "", sort_order: 1});
        return;
      }
      try {
        let response = await this.axios.get("products/get/" + this.id);
        this.formData = response.data;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Product Not Found",
            timeout: 2000,
            type: 'danger'
          });
        } else {
          console.error(error);
        }
      } finally {
      }
    },

    async prepareBuilders() {
      await this.getBuildThings();
      await this.getProduct();
      this.loader.hide();
    },
    async getBuildThings() {
      try {
        let response = await this.axios.get("products/builder");
        this.categories = response.data.items.categories;
        this.brands = response.data.items.brands;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Categories Not Found",
            timeout: 2000,
            type: 'danger'
          });
        } else {
          console.error(error);
        }
      } finally {
      }
    },
    async submit() {
      let request;
      let successMessage;
      this.submitting = true;
      let imageValidation = false;
      for (let image of this.formData.images) {
        if (!image.image) {
          imageValidation = true;
        }
      }
      if (imageValidation) {
        this.$notify({
          message: "You Must Upload Image",
          timeout: 2000,
          type: 'danger'
        });
        this.submitting = false;
        return;
      }
      if (this.formData.is_featured && !this.formData.featured_image){
          this.$notify({
              message: "You Must Upload Featured Image",
              timeout: 2000,
              type: 'danger'
          });
          this.submitting = false;
          return;
      }
      if (this.editMode === true) {
        request = this.axios.put("products/update/" + this.id, this.formData);
        successMessage = "Product Updated Successfully";
      } else {
        request = this.axios.post("products/create", this.formData);
        successMessage = "Product Added Successfully";
      }

      try {
        await request;
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/products/list");
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      } finally {
        this.submitting = false;
      }
    },

    generateMetaTitleURL() {

      let newUrl = '';
      if (this.formData.name) {
        newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }

      this.formData.url = newUrl;
      // meta title
      this.formData.meta_title = this.formData.name;
    },
    addNewSize() {
      let order = this.formData.sizes.length + 1;
      this.formData.sizes.push({"id": null, "title": "", sort_order: order});
    },
    removeSize(index) {
      this.formData.sizes.splice(index, 1);
    },
    addNewImage() {
      let order = this.formData.images.length + 1;
      this.formData.images.push({"id": null, "image": "", sort_order: order});
    },
    removeImage(index) {
      this.formData.images.splice(index, 1);
    },
      removeFeaturedImage() {
          this.formData.featured_image = "";
      }

  }
}
</script>

<style>
</style>
